import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import vmMachineApi from '@/api/gpuHub/vmMachines';
import { API_CALL_STATUS } from "@/constant/config";
import { differenceWith, intersectionWith } from 'lodash';
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
export default {

    components: {
        RdpGatewayClients: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/rdpGateway/RdpGatewayClients"),
            loading: SkeletonBox,
        }),
        CostExplorer: lazyLoadComponent({
            componentFactory: () => import("@/views/components/CostExplorer"),
            loading: SkeletonBox,
        }),
    },

    extends: baseComponent,

    data() {
        return {
            isShowFilter: false,
            rentalPackage: [],
            rdpGatewayServers: [],
            listData: [],
            isLoading: false,
            filterDateArr: [],
            pagination: {
                status: 1,
                querySearch: '',
                startTimeFilter: null,
                endTimeFilter: null,
                rdgId: null,
                remotePackageId: null,
                isConnecting: null,
                orderBy: 'A.START_TIME',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 30,
                outRowsNumber: 0,
            },
            updateInterval: null,
            rdpGatewayServersLastUpdate: null,
            rdpGatewayMetrics: {},
        };
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
        }),
        machinePackageFilter() {
            return this.rentalPackage.map(x => {
                return { id: x.id, text: x.name };
            });
        },
        rdGatewayRegionFilter() {
            return this.rdpGatewayServers.map(x => {
                return {
                    id: x.id, text: x.name,
                };
            });
        },
    },

    created() {
    },

    mounted() {
        this.loadRentalPackage();
        this.rdpGatewayAdminGetAllGateway();
        this.rdpGatewayAdminGetGatewayMetrics();
        this.getData(this.dataDefaultProcessing());
        this.updateInterval = setInterval(() => {
            this.rdpGatewayAdminGetAllGateway();
            this.rdpGatewayAdminGetGatewayMetrics();
            this.getData(this.dataIntervalUpdateProcessing(), false);
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.updateInterval);
    },
    methods: {
        loadRentalPackage() {
            vmMachineApi.getPackageList().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.rentalPackage = response.data.data.data;
                }
                else {
                    this.rentalPackage = [];
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        console.error(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rentalPackage = [];
            });
        },
        rdpGatewayAdminGetAllGateway() {
            vmMachineApi.rdpGatewayAdminGetAllGateway().then(res => {
                if (res.data && res.data.result === 0 && res.data.data !== null) {
                    this.rdpGatewayServers = res.data.data;
                    this.rdpGatewayServersLastUpdate = new Date();
                }
                else {
                    this.rdpGatewayServers = [];
                    if (res.data && res.data.message !== null && res.data.message !== '') {
                        console.error(res.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rdpGatewayServers = [];
            });
        },
        rdpGatewayAdminGetGatewayMetrics() {
            vmMachineApi.rdpGatewayAdminGetGatewayMetrics().then(res => {
                if (res.data && res.data.result === 0 && res.data.data !== null) {
                    this.rdpGatewayMetrics = res.data.data;
                }
                else {
                    this.rdpGatewayMetrics = {};
                    if (res.data && res.data.message !== null && res.data.message !== '') {
                        console.error(res.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.rdpGatewayMetrics = {};
            });
        },
        dataDefaultProcessing() {
            const procObj = {
                procReslove: (res) => {
                    try {
                        if (res.data.result === 0) {
                            this.$set(this, 'listData', res.data.data.data);
                            this.$set(this, 'pagination', {
                                status: 1,
                                querySearch: res.data.data.pagingItem.querySearch,
                                startTimeFilter: res.data.data.pagingItem.startTimeFilter,
                                endTimeFilter: res.data.data.pagingItem.endTimeFilter,
                                rdgId: res.data.data.pagingItem.rdgId,
                                remotePackageId: res.data.data.pagingItem.remotePackageId,
                                isConnecting: res.data.data.pagingItem.isConnecting,
                                orderBy: res.data.data.pagingItem.orderBy,
                                directionSort: res.data.data.pagingItem.directionSort,
                                pageIndex: res.data.data.pagingItem.pageIndex,
                                pageSize: res.data.data.pagingItem.pageSize,
                                outRowsNumber: res.data.data.pagingItem.outRowsNumber,
                            });
                            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                        }
                    } catch (error) {
                        console.log("rdpGatewayAdminGetAll -> error", error);
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                    this.isLoading = false;
                },
                procReject: (error) => {
                    console.log("rdpGatewayAdminGetAll -> error", error);
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    this.isLoading = false;
                },
            };
            return procObj;
        },
        dataIntervalUpdateProcessing() {
            return {
                procReslove: (res) => {
                    try {
                        if (res.data.result === 0) {
                            // Find any widgets we need to remove
                            const toRemove = differenceWith(this.listData, res.data.data.data, (a, b) => {
                                return (a.id === b.id);
                            });

                            const toInsert = differenceWith(res.data.data.data, this.listData, (a, b) => {
                                return (a.id === b.id);
                            });

                            const toUpdateItems = intersectionWith(res.data.data.data, this.listData, (a, b) => {
                                return (a.id === b.id);
                            }); 
                            // Remove widgets that user said to remove
                            toRemove.forEach(itemRemove => {
                                let foundIndex = this.listData.findIndex(x => {
                                    return x.id == itemRemove.id;
                                });
                                this.listData.splice(foundIndex, 1);
                            });

                            toInsert.forEach(itemInsert => {
                                let foundIndex = this.listData.findIndex(x => {
                                    return x.id == itemInsert.id;
                                });
                                if (foundIndex === -1) this.listData.unshift(itemInsert);
                                else this.listData[foundIndex] = itemInsert;
                            });

                            toUpdateItems.forEach(itemUpdate => {
                                let foundIndex = this.listData.findIndex(x => {
                                    return x.id == itemUpdate.id;
                                });
                                if (foundIndex !== -1) {
                                    this.listData[foundIndex].connectDuration = itemUpdate.connectDuration;
                                    this.listData[foundIndex].isConnecting = itemUpdate.isConnecting;
                                }
                            });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                },
                procReject: (error) => {
                    console.error(error);                    
                },
            }
        },
        getData(procData, showLoading = true) {
            if(showLoading) this.isLoading = true;
            if (this.filterDateArr !== null && this.filterDateArr.length > 0) {
                this.pagination.startTimeFilter = this.filterDateArr[0];
                this.pagination.endTimeFilter = this.filterDateArr[1];
            }
            else {
                this.pagination.startTimeFilter = null;
                this.pagination.endTimeFilter = null;
            }
            vmMachineApi.rdpGatewayAdminGetAll({
                querySearch: this.pagination.querySearch,
                startTimeFilter: this.pagination.startTimeFilter,
                endTimeFilter: this.pagination.endTimeFilter,
                rdgId: this.pagination.rdgId,
                remotePackageId: this.pagination.remotePackageId,
                isConnecting: this.pagination.isConnecting,
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
            }).then((res) => {
                procData.procReslove(res);
            }).catch(error => {
                procData.procReject(error);
            });
        },
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.$set(this, 'pagination', {
                        ...this.pagination,
                        querySearch: '',
                        startTimeFilter: null,
                        endTimeFilter: null,
                        rdgId: null,
                        remotePackageId: null,
                        isConnecting: null,
                        pageIndex: 1,
                    });
                    this.getData(this.dataDefaultProcessing());
                    break;
            }
        },
        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData(this.dataDefaultProcessing());
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData(this.dataDefaultProcessing());
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData(this.dataDefaultProcessing());
        },
        // Search list
        searchList() {
            if (this.pagination.isConnecting === '') this.pagination.isConnecting = null;
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData(this.dataDefaultProcessing());
        },
    }
}